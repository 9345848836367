<template>
  <div class="SignUpView">
    <!-- Desktop Logo !-->
    <div
      class="cursor-pointer"
      @click="redirectToWebsite()"
    >
      <Logo
        class="SignUpView__logo--primary"
        :variant="logoType"
      />
    </div>

    <!-- Left Panel !-->
    <div
      class="SignUpView__left-side ecod-gradient-background overflow-hidden"
      data-test-id="left_panel-container"
    >
      <div
        class="h-100"
        :style="{
          backgroundImage: imageUrl,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom left',
          backgroundSize: isCoverImg ? 'cover' : '',
        }"
      />
      <div class="SignUpView__left text-center emobg-color-white d-flex flex-column">
        <span
          class="SignUpView__left-side__background__content"
          data-test-id="left_panel_title-text"
        >
          {{ leftTitle }}
        </span>
        <span
          class="SignUpView__left-side__background__content emobg-font-weight-semibold"
          data-test-id="left_panel_subtitle-text"
        >{{ leftSubtitle }}
        </span>
      </div>
    </div>
    <!-- Right Panel !-->
    <div class="SignUpView__right-side">
      <NotificationListManager class="position-fixed SignUpView__notification" />
      <!-- Responsive Logo !-->
      <div
        class="text-center full-width"
        @click="redirectToWebsite()"
      >
        <Logo class="SignUpView__logo--responsive py-4" />
      </div>
      <!-- View -->
      <div class="SignUpView__right-side__wrapper full-width h-100">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import Logo from '@/components/Logo/Logo';
import { LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { WEBSITE_DOMAIN } from '@/constants/app';

export default {
  components: {
    NotificationListManager,
    Logo,
  },
  props: {
    leftImage: {
      type: [String, Object],
      default: '',
    },
    leftTitle: {
      type: String,
      default: '',
    },
    leftSubtitle: {
      type: String,
      default: '',
    },
    isCoverImg: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const logoType = LOGOS_KEYNAMES.CONTRAST_LOGO;
    const imageUrl = `url(${props.leftImage})`;
    const redirectToWebsite = () => {
      window.open(WEBSITE_DOMAIN, '_self');
    };
    return {
      logoType,
      imageUrl,
      redirectToWebsite,
    };
  },
};
</script>
